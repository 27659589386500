import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './informe.css';
import { STATUS, clearInforme, editInforme, persistInforme } from './informeSlice';
import { selectAuthenticated } from '../auth/authSlice';
import Login from '../auth/Login';
import Spinner from '../../components/Spinner';
import PersonasInvolucradas from './PersonasInvolucradas';
import Profesionales from './Profesionales';
import { plataformas, escenarios } from '../../app/utils.js';

export default function NuevoInforme () {
    const navigate = useNavigate();
    const userAuthenticated = useSelector(selectAuthenticated);
    /* useEffect(() => {
        if (!userAuthenticated) {
            navigate('/login');
        }
    }, [userAuthenticated]); */

    const dispatch = useDispatch();
    const { informe, status, error } = useSelector((state) => state.informe);

    // la primera vez que se carga la página, se limpian los datos
    // por si tuviéramos algo retenido de antes
    useEffect(()=>{
        dispatch(clearInforme());
    }, []);
    
    // una vez que se guarda, se limpian los datos y volvemos al menú de inicio
    useEffect(() => {
        if (status === STATUS.SAVED) {
            //alert(`Creados informe y expediente nº ${informe.numero}`);
            alert('Informe de notificación enviado.');
            dispatch(clearInforme());  
            navigate('/');
        }
    }, [status]);

    if (!userAuthenticated) {
        return <Login />;
    }

    function onChangeInformeHandler({target}) {
        const userInput = target.value;
        const field = target.id;
        dispatch(editInforme({
            key: field,
            value: userInput
        }));
    }

    function onClickHandler({target}) {
        if (target.id === 'volver') {
            navigate('/');
            return;
        }
        // en cualquier otro caso, se trata de guardar
        dispatch(persistInforme());
    }

    return (
        <> 
            { status === STATUS.SAVING 
                ? <>
                    <p>Guardando...</p>
                    <Spinner/>
                </>
                : ''
            }
            { error === STATUS.COULDNT_SAVE
                ? <>
                    <h2>ERROR: No se ha podido guardar.</h2>
                </>
                : ''
            }
            <div className='title'>
                <h2>Informe de notificación interno</h2>
                <h3>Comunicación de dudas, sospechas o certezas sobre posibles
                situaciones de violencia o trato inadecuado a niños, niñas,
                adolescentes y/o personas en situación de vulnerabilidad
                </h3>
            </div>
            <button id="volver" onClick={onClickHandler}>Atrás</button>
            <p className='reminder'>* Recuerde guardar el informe cuando termine de cumplimentarlo.</p>
            <div className='content'>
                <h3>Confidencialidad:</h3>
                <p>Toda la información contenida en el presente Informe será tratada 
                con la confidencialidad exigida por el Protocolo de Actuación 
                de la entidad y la normativa vigente en materia de datos 
                de carácter personal.</p>
                <form>
                    <h3>¿De qué tipo de escenario se trata?</h3>
                    <select id="escenario" name="escenario"
                        value={informe.escenario || ''}
                        onChange={onChangeInformeHandler}>
                        {escenarios.map((option, index) => (
                            <option key={index} value={index+1}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <h3>Datos de registro:</h3>
                    {/** PROVISIONAL */}
                    {/* ESTA PARTE AHORA SE HACE EN EL SERVIDOR
                    <label htmlFor="fecha">Fecha:</label>
                    <input type="date" id="fecha" name="fecha"
                        value={informe.fecha || ''}
                        onChange={onChangeInformeHandler}/>
                    <label htmlFor="hora">Hora:</label>
                    <input type="time" id="hora" name="hora"
                        value={informe.hora || ''}
                        onChange={onChangeInformeHandler}/>
                    */}
                    {/** FIN PROVISIONAL */}
                    <label htmlFor="plataforma">Plataforma:</label>
                    <select id="plataforma" name="plataforma"
                        value={informe.plataforma || ''}
                        onChange={onChangeInformeHandler}>
                        {plataformas.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <h3>Datos de la persona notificante:</h3>
                    <label htmlFor="nombre_notificante">Nombre:</label>
                    <input type="text" id="nombre_notificante" name="nombre_notificante"
                        value={informe.nombre_notificante || ''}
                        onChange={onChangeInformeHandler}/>
                    <label htmlFor="funcion_notificante">Puesto de trabajo / función:</label>
                    <input type="text" id="funcion_notificante" name="funcion_notificante"
                        value={informe.funcion_notificante || ''}
                        onChange={onChangeInformeHandler}/>
                    <label htmlFor="relacion_notificante">Relación con la persona afectada*: <span className="clarification">(* niño, niña, adolescente o persona en situación de vulnerabilidad)</span></label>
                    <input type="text" id="relacion_notificante" name="relacion_notificante"
                        value={informe.relacion_notificante || ''}
                        onChange={onChangeInformeHandler}/>
                    <label htmlFor="telefono_notificante">Teléfono:</label>
                    <input type="text" id="telefono_notificante" name="telefono_notificante"
                        value={informe.telefono_notificante || ''}
                        onChange={onChangeInformeHandler}/>
                    <label htmlFor="email_notificante">E-mail:</label>
                    <input type="text" id="email_notificante" name="email_notificante"
                        value={informe.email_notificante || ''}
                        onChange={onChangeInformeHandler}/>

                    <h3>Datos de la persona que cumplimenta el informe (en caso de no ser la notificante):</h3>
                    <label htmlFor="nombre_cumplimenta">Nombre:</label>
                    <input type="text" id="nombre_cumplimenta" name="nombre_cumplimenta"
                        value={informe.nombre_cumplimenta || ''}
                        onChange={onChangeInformeHandler}/>
                    <label htmlFor="funcion_cumplimenta">Función dentro del Sistema de Protección Interno:</label>
                    <input type="text" id="funcion_cumplimenta" name="funcion_cumplimenta"
                        value={informe.funcion_cumplimenta || ''}
                        onChange={onChangeInformeHandler}/>
                    <label htmlFor="telefono_cumplimenta">Teléfono:</label>
                    <input type="text" id="telefono_cumplimenta" name="telefono_cumplimenta"
                        value={informe.telefono_cumplimenta || ''}
                        onChange={onChangeInformeHandler}/>
                    <label htmlFor="email_cumplimenta">E-mail:</label>
                    <input type="text" id="email_cumplimenta" name="email_cumplimenta"
                        value={informe.email_cumplimenta || ''}
                        onChange={onChangeInformeHandler}/>

                    <h3>Datos de la persona afectada:</h3>
                    <p>Si se ven involucradas varias personas de la misma familia, 
                    se escriben todos los nombres. Si en la situación hay personas 
                    de diferentes grupos familiares, hay que completar un informe 
                    de notificación interno por cada grupo familiar.</p>
                    <label htmlFor="nombre_afectada">Nombre:</label>
                    <input type="text" id="nombre_afectada" name="nombre_afectada"
                        value={informe.nombre_afectada || ''}
                        onChange={onChangeInformeHandler}/>
                    <label htmlFor="edad_afectada">Edad:</label>
                    <input type="text" id="edad_afectada" name="edad_afectada"
                        value={informe.edad_afectada || ''}
                        onChange={onChangeInformeHandler}/>
                    <label htmlFor="sexo_afectada">Sexo:</label>
                    <input type="text" id="sexo_afectada" name="sexo_afectada"
                        value={informe.sexo_afectada || ''}
                        onChange={onChangeInformeHandler}/>
                    <label htmlFor="programa_afectada">Programa en el que participa:</label>
                    <input type="text" id="programa_afectada" name="programa_afectada"
                        value={informe.programa_afectada || ''}
                        onChange={onChangeInformeHandler}/>
                
                    <h3>Datos de la/s persona/s involucrada/s:</h3>
                    <PersonasInvolucradas editable={true} />

                    <h3>Origen o fuente de la detección:</h3>
                    <label htmlFor="deteccion">¿Cómo tuvo noticia de la situación?</label>
                    <textarea id="deteccion" name="deteccion"
                        onChange={onChangeInformeHandler}>
                        {informe.deteccion || ''}
                    </textarea>

                    <h3>Descripción de la consulta, situación o de los motivos de sospecha:</h3>
                    <p>Realice una descripción lo más detallada posible de los hechos.</p>
                    <p>Resulta de especial importancia conocer –de la forma más exacta posible- 
                    lo que dijo la persona afectada.</p>
                    <label htmlFor="fecha_hora_suceso">Día y hora del suceso:</label>
                    <input type="text" id="fecha_hora_suceso" name="fecha_hora_suceso"
                        value={informe.fecha_hora_suceso || ''}
                        onChange={onChangeInformeHandler}/>
                    <label htmlFor="involucradas_suceso">Personas involucradas en esta situación concreta:</label>
                    <textarea id="involucradas_suceso" name="involucradas_suceso"
                        onChange={onChangeInformeHandler}>
                        {informe.involucradas_suceso || ''}
                    </textarea>
                    <label htmlFor="que_donde_suceso">Qué ha sucedido y dónde, de la situación concreta:</label>
                    <textarea id="que_donde_suceso" name="que_donde_suceso"
                        onChange={onChangeInformeHandler}>
                        {informe.que_donde_suceso || ''}
                    </textarea>

                    <h3>¿Qué se ha hecho al respecto?</h3>
                    <p>¿Qué actuaciones se han llevado a cabo y quién las ha llevado a cabo?</p>
                    <p>Por parte de la persona notificante, por parte de otras personas...</p>
                    <label htmlFor="actuaciones_menor">Con respecto a la persona afectada:</label>
                    <textarea id="actuaciones_menor" name="actuaciones_menor"
                        onChange={onChangeInformeHandler}>
                        {informe.actuaciones_menor || ''}
                    </textarea>
                    <label htmlFor="actuaciones_familia">Con respecto a la familia:</label>
                    <textarea id="actuaciones_familia" name="actuaciones_familia"
                        onChange={onChangeInformeHandler}>
                        {informe.actuaciones_familia || ''}
                    </textarea>
                    <label htmlFor="actuaciones_otros">Con respecto a...</label>
                    <textarea id="actuaciones_otros" name="actuaciones_otros"
                        onChange={onChangeInformeHandler}>
                        {informe.actuaciones_otros || ''}
                    </textarea>
                    <label htmlFor="comunicacion">¿Se ha hecho comunicación a algún servicio público?
                    Consigne a cuál, quién lo hizo y de qué forma.</label>
                    <textarea id="comunicacion" name="comunicacion"
                        onChange={onChangeInformeHandler}>
                        {informe.comunicacion || ''}
                    </textarea>

                    <h3>Datos de otras posibles profesionales que trabajan con la familia:</h3>
                    <Profesionales editable={true} />

                    <h3>Observaciones:</h3>
                    <label htmlFor="observaciones">Puede comentar aquí cualquier otra
                cuestión que considere de interés para el caso.</label>
                    <textarea id="observaciones" name="observaciones"
                        onChange={onChangeInformeHandler}>
                        {informe.observaciones || ''}
                    </textarea>
                </form>
            </div>
            <div id='form-ending'>
                <h2>Muchas gracias por su colaboración. Está cumpliendo con su obligación legal y moral.</h2>
                <button id="guardar" onClick={onClickHandler}>Guardar informe</button>  
            </div>
        </>
    );
}